@font-face {
  font-family: 'Comfortaa';
  src: url('./assets/Comfortaa-VariableFont_wght.ttf');
}
*{
    box-sizing: border-box;
    font-family: 'Comfortaa', cursive;

}

.App {
    overflow: hidden;
}

.Container{
    max-width: 1000px;
    width: 100%;
    margin: auto;
    padding: 1em;
    margin-top: 75px;
}

@media(min-width: 800px) {
    .Container{
        margin-top:0;
    }
}
