
.Licenses{
    .image{
        width: 75px;
        height: auto;
        display: inline-block;
    }
    .card{
        display: flex;
        align-items: center;
        width: 50%;
        margin:1em;
    }
}
