@font-face {
  font-family: 'Staatliches';
  src: url("../../assets/Staatliches-Regular.ttf");
}

.Navbar-Desktop{
  font-family: 'Staatliches', cursive;
  max-width: 1000px;
  margin: auto;
.header-nav-list{

  box-sizing: border-box;
  text-align: center;
  padding: 0;
  width: 100%;
}
ul{
  list-style: none;
  padding-left: 0;
  display: flex;
  background-color: white;
}
.dropdown{
  display: none;
  position: absolute;
  width: 100%;
  flex: 1;
  z-index: 500;
  a{
      font-size: 16px;
  }
}
.nav-item{
  font-family: 'Staatliches', cursive;
  display: inline-block;
  background-color:  #2a5bc4;
  border: none;
  color: white;
  text-decoration: none;
  font-size: 20px;
  width: 100%;
  height: 100%;
  padding: 5px;
  border-left: 2px solid white;
  border-right: 2px solid white;
  flex: 1;

  &:hover{
      background-color: grey;
  }
}
.nav-group{
  width: 100%;
  position: relative;

  &:hover .dropdown{
      display: block;
  }
}


}
@media(max-width: 799px) {

    .Navbar-Desktop {
      display: none;
    }
  }