.faqs {
  h2 {
    text-align: center;
  }
  .question {
    margin-bottom: 2em;
  }
  .answer {
    color: white;
    display: flex;
    justify-content: flex-end;
    a {
      color: white;
      &:hover {
        color: #1982c4;
        background-color: white;
      }
    }
  }

  .faq {
    margin-bottom: 5em;
  }
  .card {
    max-width: 80%;
    display: flex;
    align-items: flex-start;
  }
}

@media (min-width: 800px) {
  .faqs {
    .card {
      max-width: 75%;
    }
  }
}
