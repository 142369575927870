.Footer{
    width: 100vw;
    
    .Top {
        padding: 1em;
        max-width: 1000px;
        border-top: 3px dotted black;
        width: 100vw;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .philosophy{
            // color: white;
            *:not(ol){
                background-color: white;
                padding: .5em;
                display: inline-block;

            }
            ol{
                padding: 0;
            }
            li{
                display: list-item;
            }
            h2{
                margin: 0;
            }
        }
        .links{

            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            a{
                color: black;
                background-color: white;
                padding: .5em;
                margin: 5px;
                display: inline-block;
                &:hover{
                    color: white;
                    background-color: black;
                }
            }
        }
        
        
    }
    .Bottom{
        padding: 1em;
        max-width: 1000px;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        a{
            color: black;
        }
    }
}
@media(min-width: 800px) {
    .Footer{
        .Top{
            flex-direction: row;
            align-items: flex-start;
            > div{
                width: 50%;
            }
        }
    }
}