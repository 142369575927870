@font-face {
    font-family: 'Staatliches';
    src: url("../../assets/Staatliches-Regular.ttf");
}

.menu{
    font-family: 'Staatliches', cursive;
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    height: 100vh;
    box-shadow: -10px 0px 10px black;
    background-color:white;
    transition: .5s;
    overflow-y: scroll;

    &.closed{
        right: -320px;
    }

    &-header{

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .5em;

        h1{
            margin: 0;
        }
        .close{
            display: flex;
            align-items: center;
            font-size: 30px;
            background-color: transparent;
            border: none;
        }
    }
    
    &-body{
        margin-top: 1em;
        a,button{
            font-family: 'Staatliches', cursive;
            text-decoration: none;
            color: black;
            background-color: lightgray;
            border: none;
            border-bottom: 4px solid black;
            display: block;
            text-align: left;
            font-size: 30px;
            width: 100%;
            margin: 0;
            padding: 0;
            padding-left: .5em;
        }
        ul{
            list-style: none;
            padding: 0;
            margin: 0;
        }
        ul a{
            background-color: white;
        }
        .ReactCollapse--collapse{
            transition: .5s;
        }
    }
    &-footer{
        margin-top: 1em;
        display: flex;
        justify-content: space-around;

        svg{
            font-size: 50px;
            padding: 7px;
            border-radius: 50%;
            transition: 1s;
            color: white;
            background-color: #4169e1;
        }
    }



    

}